import * as React from 'react';

import { graphql } from 'gatsby';

import CampaignDetailsModule from '../../modules/campaignDetails';
import { Typography } from '@mui/material';

const CampaignDetailsPage = (props: any) => {
  return (
    <CampaignDetailsModule campaignKey="donation-mukachevo" showDonationDescription={false}>

      <Typography paragraph={true}>
        There is a growing number of people that are going to the western part of Ukraine from the cities affected by war. Some stays at the
        refugee camps and some after a short stop continue to other Ukrainian cities or abroad.
      </Typography>

      <Typography paragraph={true}>
        There are currently 37 people that are staying at the camp, including women, children, and older people, from different areas of
        Ukraine. An old factory was transformed into the place for people to stay. It was organized with a help of volunteers and a
        volunteer organization. Refrigerators, washing machines, electric kettles, ovens, microwaves, as well as beds, bedsheets, detergents
        and food is being organized by volunteers.
      </Typography>

      <Typography paragraph={true}>
        They will be very grateful for any financial support that can be provided to cover the cost of utilities that volunteers cannot cover
        on their own due to the very high costs.
      </Typography>

      <Typography variant="h6" paragraph={true}>Bank details</Typography>

      <Typography paragraph={true}>
        <Typography>Beneficiary: BO BF TaKo</Typography>
        <Typography>Account: UA92 3005 2800 0002 6000 0000 21746</Typography>
        <Typography>Beneficiary's bank: OTP BANK JSC</Typography>
        <Typography>SWIFT code: OTPVUAUK</Typography>
      </Typography>

      <Typography paragraph={true}>
        <b>Please let us know how much you have donated.</b>
      </Typography>

    </CampaignDetailsModule>
  );
};

export default CampaignDetailsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
